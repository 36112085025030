/* .modal-filter-date-dashboard > .ant-modal-content {
} */

.modal-filter-date-dashboard > .ant-modal-content > .ant-modal-body {
  padding: 12px;
  /* min-width: 780px; */
}

.conten-modal-filter {
  width: 100%;
  display: flex;
  min-height: 360px;
}

.title-date {
  margin-top: 5px;
  width: 25%;
  border-right: 1px solid #d3d3d3;
  max-width: 165px;
}

.title-modal-dashboard {
  width: 100%;
  margin: 6px 0;
  padding: 2px 12px;
  cursor: pointer;
}

.active {
  color: #3961a0;
}

.title-modal-dashboard:hover {
  background: #e3f3ff;
}

.list-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-modal-dashboard {
  width: calc(100% - 12px);
  margin: 12px 0;
  border-top: 1px solid #d3d3d3;
  padding: 0 12px;
}

.conten-date {
  width: 80%;
  padding: 12px;
  position: relative;
}

.acction-modal-filter {
  width: 100%;
  display: flex;
  justify-content: end;
}

.button-ok {
  width: 80px;
  border-radius: 10px !important;
  color: white !important;
  background: #3961a0 !important;
}

.space-datePicker {
  position: absolute;
  top: -12px;
}

.space-datePicker-option-from {
  position: absolute;
  top: 0px;
}

.space-datePicker-option-to {
  position: absolute;
  top: 0px;
  right: 145px;
}

.datePicker-input {
  opacity: 0;
  height: 15px;
}

.popup-calendar
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-week-panel {
  width: 540px;
}

.popup-calendar
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-date-panel {
  width: 540px;
}

.popup-calendar
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-month-panel {
  width: 540px;
}

.popup-calendar
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-year-panel {
  width: 540px;
}

.popup-calendar
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-date-panel
  > .ant-picker-body
  > table {
  width: 540px;
}

.popup-calendar
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-footer {
  display: none;
}

.popup-calendar-option
  > .ant-picker-panel-container
  > .ant-picker-panel
  > .ant-picker-footer {
  display: none;
}

.content-date-option {
  color: #3961a0;
  font-size: 15px;
}
