tr td:first-child {
  border-top-left-radius: 20px;
}

tr td:first-child {
  border-bottom-left-radius: 20px;
}

tr td:last-child {
  border-bottom-right-radius: 20px;
}

tr td:last-child {
  border-top-right-radius: 20px;
}

tr {
  border-top: 7px #fafafa solid;
  border-bottom: 7px #fafafa solid;
}

.total-by-merchant svg.recharts-surface {
  height: 420px !important;
}

.total-by-merchant.recharts-wrapper {
  position: absolute !important;
  /* bottom: 240px; */
}

.total-by-merchant .recharts-legend-wrapper {
  bottom: -10px !important;
}

.before::before {
  position: absolute;
  left: 0;
  height: 100%;
  background: #2A3790 !important;
  width: 3px !important;
  content: '';
}

@media (min-width: 1800px) {
  .css-1oqqzyl-MuiContainer-root {
    max-width: 1800px !important;
  }
}

.semi-rounded {
  border-radius: 4px !important;
  border-color: #2A3790 !important;
}

.rounded {
  border-radius: 8px !important;
  border-color: #2A3790 !important;
}

.rounded.ant-input-disabled {
  border-color: #969696 !important;
}

.ant-switch-checked {
  background-color: #2A3790 !important;
}

.ant-switch-handle {
  top: 6px !important;
}

.ant-modal-content {
  border-radius: 16px !important;
}

.ant-modal-footer {
  border-top: none;
}

.ant-select-selector {
  border-radius: 4px !important;
  border-color: #2A3790 !important;
}

.rounded .ant-select-selector {
  border-radius: 8px !important;
  border-color: #2A3790 !important;
}

.ant-input-status-error.rounded {
  border-color: #BF272D !important;
}

.premium-checkbox input {
  -webkit-transform: scale(1.5);
  /* background-color: black; */
  transform: scale(1.5);
  accent-color: #2A3790 !important;
}

.premium-checkbox label {
  margin-left: 5px;
  text-transform: capitalize;
  font-size: 14px;
  vertical-align: top;
}

.transparent-background .ant-select-selector{
  background-color: transparent !important;
}

.premium-radio {
  display: flex;
  align-items: center;
  /* margin: 15px 20px; */
  cursor: pointer;
}

input[type="radio"] {
  opacity: 0;
}

.design {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline-offset: 3px;
  outline: 1px solid #888888;
  margin-right: 8px;
}

input[type="radio"]:checked~.design {
  outline-color: #2A3790;
  background: #2A3790;
}

.premium-radio span{
  font-size: 13.5px !important;
  /* font-weight: 500; */
  /* line-height: 10px; */
}

.effected-hover{
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.effected-hover:hover{
  background-color: #EBEDFF !important;
}

.ant-carousel .slick-dots li button{
  background-color: gray !important;
}

.slick-dots.slick-dots-bottom{
  margin-bottom: -1em !important;
}

.ant-carousel .slick-dots-bottom{
  bottom: 0px !important
}

.clearable > .ant-input{
  background-color: transparent !important;
}

.custom-editor {
  padding: 24px 200px;
}

.DraftEditor-root {
  min-height: 200px;
  border: solid 1px #bbb;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ABABAB;
  background: #fefefe;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
  max-height: 360px !important;
  overflow-y: auto;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.modal-detail .ant-modal-header{
  border-radius: 20px !important;
}

.text-right .ant-input{
  text-align: right;
}