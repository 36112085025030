.datePicker-overide .adm-picker-view {
  flex-flow: row-reverse;
}

.datePicker-overide .adm-picker-header {
  flex-shrink: 0;
  border-bottom: solid 1px var(--adm-color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px;
  background: #284078;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.datePicker-overide .adm-picker-header-button {
  font-size: 17px;
  display: inline-block;
  color: white;
  padding: 8px 8px;
  font-weight: 600;
}

.timePicker-overide .adm-picker-header {
  flex-shrink: 0;
  border-bottom: solid 1px var(--adm-color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px;
  background: #284078;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.timePicker-overide .adm-picker-header-button {
  font-size: 17px;
  display: inline-block;
  color: white;
  padding: 8px 8px;
  font-weight: 600;
}

.timePicker-overide .adm-picker-body .adm-picker-view .adm-picker-view-column {
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb;
}
.timePicker-overide
  .adm-picker-body
  .adm-picker-view
  .adm-picker-view-column
  .adm-picker-view-column-wheel
  .adm-picker-view-column-item {
  font-size: 21px;
}
