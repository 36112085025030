#car-accident .header-title {
  background-color: rgba(225, 107, 47, 1);
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  align-items: center;
  padding-left: 16px;
}

#car-accident .item-info {
  align-items: center;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
}

.ant-picker-input > input:placeholder-shown {
  font-size: 17px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #E16B2F !important;
  border-color: #E16B2F !important
}

.ant-checkbox-checked::after {
  border: 1px solid #E16B2F !important;
}

#container-circles {
  bottom: 0 !important;
}

.modalCamera > .ant-modal-content {
  display: flex;
  justify-content: center;
  background-color: black;
}

.react-html5-camera-photo>video {
  max-width: 400px;
}

#select_gender .ant-select .ant-select-selector {
  border-radius: 10px !important;
  height: 53px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#select_gender .ant-select-arrow {
  top: 30px !important;
}

#select_bank .ant-select .ant-select-selector {
  border-radius: 10px !important;
  height: 53px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#select_bank .ant-select-arrow {
  top: 30px !important;
}

.img-accident {
  max-height: 100px;
  object-fit: contain;
  max-width: 100px;
  border-radius: 10px;
}

.show-modal-image {
  object-fit: contain;
}

.icon-close {
  font-size: 18px;
  color: rgba(90, 90, 90);
  position: absolute;
  right: -3;
  top: -3;
}

#select_gender .ant-select .ant-select-selector {
  border-color: #d9d9d9 !important
}
