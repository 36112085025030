.img-modal {
  height: 400px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.title-header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-loading > .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important
}
