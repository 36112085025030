@font-face {
  font-family: Google Sans;
  src: url("../fonts/OpenSans-Medium.ttf");
}

html * {
  font-family: "Google Sans" !important;
}

.swal2-confirm.swal2-styled {
  background: #2b65d8 !important;
}

.MuiFilledInput-root {
  background: none !important;
}

.MuiFilledInput-adornedStart {
  padding: 0 !important;
}

.color-danger {
  color: "#BF272D";
}

#input-no-underline .MuiFilledInput-underline:before {
  display: none;
}

#input-no-underline .MuiFilledInput-underline:after {
  display: none;
}

#textfield__no-underline.MuiFilledInput-input {
  padding: 0;
  height: 53px;
}

#input-no-underline .MuiSelect-select:focus {
  background-color: white;
}

.Mui-checked > .MuiSvgIcon-root {
  fill: "#25806D" !important;
}

.MuiFilledInput-underline:before {
  border: none !important;
}

.MuiFilledInput-underline:after {
  border: none !important;
}

#textfield__no-underline {
  background-color: white;
}

#textfield__no-underline:disabled {
  background-color: #f1f1f1;
}

#textfield__no-underline[name="dateOfHospital"]:disabled {
  background-color: #fff !important;
}

#textfield__no-underline[name="dateOutOfHospital"]:disabled {
  background-color: #fff !important;
}

#textfield__no-underline[name="dayOfTreatment"]:disabled {
  background-color: #fff !important;
}

#textfield__no-underline[name="dateOfAccident"]:disabled {
  background-color: #fff !important;
}

#textfield_green__no-underline {
  background-color: white;
  color: #25806d !important;
  border-color: #979797;
}

.MuiFormLabel-root.Mui-focused {
  color: black !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #25806d !important;
}

.MuiAutocomplete-inputRoot {
  background: white !important;
}
#label-select-step2.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled.Mui-focused.Mui-focused {
  margin-top: 10px;
}
#label-select-step2.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  margin-top: 10px;
}

#textfield__no-underline::placeholder {
  color: black !important;
  font-size: 15px !important;
}

#textfield__no-underline:disabled::placeholder {
  color: #c4cfc9 !important;
  font-size: 12px !important;
}

.MuiFormLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.87) !important;
}

#textfield__no-underline[name="buyerName"] {
  text-transform: capitalize !important;
}

#yearOfManufacture .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.75);
}

#yearOfManufacture label.Mui-focused {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

#hmbh select {
  color: #25806d;
}

@media only screen and (max-width: 500px) {
  #yearOfManufacture label.MuiInputLabel-filled {
    transform: translate(12px, 13px) scale(1);
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(37, 128, 109);
}

.MuiRadio-root.Mui-checked {
  color: #0b3c8a !important;
}

.MuiInputBase-multiline {
  padding: 12px 0px !important;
}

.ItemDropDown .anticon > svg {
  fill: #fff !important;
}
