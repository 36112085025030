#check-box .Mui-checked {
  color: #e16b2f !important;
}
#check-box .MuiCheckbox-indeterminate {
  color: #e16b2f !important;
}

#input_select_gara .ant-select .ant-select-selector {
  border-radius: 10px !important;
  height: 56px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#input_select_gara .ant-select-arrow {
  top: 30px !important;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}

#sl > .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.ant-table-container {
  width: 100%;
  overflow: auto;
}

.ant-table table {
  min-width: 1250px;
}

.table-account-maneger .ant-table-cell {
  text-align: center;
}
